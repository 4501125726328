import React, { Component } from "react";

import Arrow from "../../svg/arrowSmall.svg";
import progressStyles from "./ProgressRing.module.css";

export default class ProgressRing extends Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const {
      radius,
      stroke,
      progress,
      right
    } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    const arrowClasses = [progressStyles.arrow];
    if ( right ) arrowClasses.push( progressStyles.right );

    return (
      <div className={progressStyles.progress}>
        <svg
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            className={progressStyles.bg}
            stroke="white"
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ this.circumference + ' ' + this.circumference }
            r={ this.normalizedRadius }
            cx={ radius }
            cy={ radius }
          />
          <circle
            className={progressStyles.circle}
            stroke="white"
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ this.circumference + ' ' + this.circumference }
            style={ { strokeDashoffset } }
            r={ this.normalizedRadius }
            cx={ radius }
            cy={ radius }
          />
        </svg>
        <img src={Arrow} className={arrowClasses.join( " " )} alt="" />
      </div>
    );
  }
}
