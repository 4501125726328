import React, {Component} from "react";
import {graphql} from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import Layout from "../components/layout.js";
import Viewer from "../components/Viewer/Viewer.js";
import MobileViewer from "../components/MobileViewer/MobileViewer.js";
import Splash from "../components/Splash/Splash.js";
import Cap from "../components/Cap/Cap.js";
import Loader from "../components/Loader/Loader.js";
import islandologyStyles from "../components/Islandology/Islandology.module.css";

export default class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      visibleItems: [],
      queuedItems: [],
      visibleItemCount: 4,
      panels: 0,
      looseItems: 0,
      loadingInterval: null,
      loaderIsVisible: true,
      splashInterval: null,
      splashIsVisible: true,
      viewerIsLive: false,
      counter: 0,
      counterIsAtBeginning: true,
      counterIsAtEnd: false,
      capIsVisible: false,
      viewportWidth: "desktop",
      partialPanel: false,
    };

    this.hideSplash = this.hideSplash.bind(this);
    this.moveItemsRight = this.moveItemsRight.bind(this);
    this.moveItemsLeft = this.moveItemsLeft.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    const loadingInterval = setTimeout(() => {
      this.setState({loaderIsVisible: false});
    }, 2000);

    let allItems = this.props.data.allWordpressAcfIslandologies.edges;
    allItems.sort((a, b) => {
      let firstIslandologyNumber = parseInt(a.node.acf.islandology_number, 10);
      let secondIslandologyNumber = parseInt(b.node.acf.islandology_number, 10);

      return firstIslandologyNumber - secondIslandologyNumber;
    });

    this.setState(state => {
      const items = allItems;
      const visibleItemCount = allItems.length > 4 ? 4 : allItems.length;
      const visibleItems = [...allItems];
      const queuedItems = visibleItems.splice(visibleItemCount);
      const panels = Math.floor(allItems.length / visibleItemCount);
      const looseItems = allItems.length % state.visibleItemCount;

      return {
        items,
        visibleItemCount,
        visibleItems,
        queuedItems,
        loadingInterval,
        panels,
        looseItems,
      };
    });

    const hasClosedSplash = window.sessionStorage.getItem("splashClosed");
    if (hasClosedSplash) this.hideSplash();

    let sliderPosition = window.sessionStorage.getItem("sliderPosition") || 0;
    while (sliderPosition > 0) {
      this.moveItemsRight();
      sliderPosition--;
    }

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    clearTimeout(this.state.loadingInterval);
    clearTimeout(this.state.splashInterval);

    window.removeEventListener("resize", this.updateDimensions);
    window.sessionStorage.setItem("sliderPosition", this.state.counter);
  }

  hideSplash() {
    const splashInterval = setTimeout(() => {
      this.setState({viewerIsLive: true});
    }, 300);

    this.setState({
      splashIsVisible: false,
      splashInterval,
    });
  }

  updateDimensions() {
    if (typeof window !== "undefined" && window.innerWidth <= 980) {
      this.setState(
        state => state.viewportWidth !== "mobile" && {viewportWidth: "mobile"}
      );
    } else {
      this.setState(
        state => state.viewportWidth !== "desktop" && {viewportWidth: "desktop"}
      );
    }
  }

  moveItemsRight() {
    this.setState(state => {
      if (state.counter === state.panels) return {capIsVisible: true};

      const partialPanel = state.counter === state.panels - 1;
      let queuedItems = [...state.queuedItems];
      let visibleItems = [...state.visibleItems];

      if (partialPanel) {
        visibleItems = [
          ...state.visibleItems.splice(
            state.looseItems,
            state.visibleItems.length - state.looseItems
          ),
          ...queuedItems.splice(0, state.looseItems),
        ];
        queuedItems.push(...state.visibleItems.splice(0, state.looseItems));
      } else {
        visibleItems = queuedItems.splice(0, state.visibleItemCount);
        queuedItems.push(...state.visibleItems);
      }

      let newState = {
        visibleItems,
        queuedItems,
        counter: state.counter + 1,
        counterIsAtBeginning: false,
        partialPanel,
      };

      return newState;
    });
  }

  moveItemsLeft() {
    this.setState(state => {
      if (state.capIsVisible) return {capIsVisible: false};

      let queuedItems = [...state.queuedItems];
      let visibleItems = [...state.visibleItems];

      if (state.partialPanel) {
        let looseItems = visibleItems.splice(
          state.visibleItemCount - state.looseItems,
          state.looseItems
        );

        visibleItems.splice(
          0,
          0,
          ...queuedItems.splice(state.queuedItems.length - state.looseItems)
        );
        queuedItems.splice(0, 0, ...looseItems);
      } else {
        visibleItems = queuedItems.splice(
          state.queuedItems.length - state.visibleItemCount,
          state.visibleItemCount
        );
        queuedItems.splice(state.queuedItems.length, 0, ...state.visibleItems);
      }

      let newState = {
        visibleItems,
        queuedItems,
        counter: state.counter - 1,
        counterIsAtBeginning: false,
        counterIsAtEnd: false,
        partialPanel: false,
      };

      if (newState.counter === 0) newState.counterIsAtBeginning = true;

      return newState;
    });
  }

  render() {
    const {
      items,
      visibleItems,
      loaderIsVisible,
      splashIsVisible,
      viewerIsLive,
      counterIsAtBeginning,
      capIsVisible,
      viewportWidth,
    } = this.state;

    const featuredItems =
      items &&
      items.filter(item => {
        return item.node.acf.featured_islandology;
      });

    const metaTitle = `That's Islandology | The Beaches of Fort Myers and Sanibel`;
    const metaDescription = `Islandology is a state of mind and our way of life. It leads to unforgettable experiences you can only have here.`;

    const display =
      viewportWidth === "desktop" ? (
        <Viewer
          isLive={viewerIsLive && !capIsVisible}
          items={visibleItems}
          onNextClick={this.moveItemsRight}
          onPrevClick={this.moveItemsLeft}
          showNext={!capIsVisible && viewerIsLive}
          showPrev={!counterIsAtBeginning && (viewerIsLive || capIsVisible)}
        />
      ) : (
        <MobileViewer isLive={viewerIsLive} items={items} />
      );

    return (
      <Layout>
        <Helmet
          title={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content:
                items.length > 0 &&
                items[0].node.acf.islandology_bkgd.localFile.relativePath,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ].concat()}
        />
        <div className={islandologyStyles.view}>
          {!viewerIsLive && (
            <Splash
              isVisible={splashIsVisible}
              items={featuredItems}
              onExploreClick={this.hideSplash}
            />
          )}

          {display}

          {viewportWidth === "desktop" && (
            <Cap view="desktop" isVisible={capIsVisible} />
          )}

          <Loader isHidden={!loaderIsVisible} />
        </div>

        <div className={islandologyStyles.imageCache}>
          {items.map(item => (
            <div key={"cache_" + item.node.acf.islandology_number}>
              {item.node.acf.islandology_bkgd && (
                <Img
                  alt=""
                  sizes={
                    item.node.acf.islandology_bkgd.localFile.childImageSharp
                      .sizes
                  }
                />
              )}
              {item.node.acf.featured_islandology_image && (
                <Img
                  alt=""
                  sizes={
                    item.node.acf.featured_islandology_image.localFile
                      .childImageSharp.sizes
                  }
                />
              )}
            </div>
          ))}
        </div>
      </Layout>
    );
  }
}

export const allIslandologiesQuery = graphql`
  query {
    allWordpressAcfIslandologies {
      edges {
        node {
          id
          acf {
            ambient_video
            featured_islandology
            islandology_number
            islandology_video
            islandology_bkgd {
              localFile {
                relativePath
                childImageSharp {
                  sizes(maxWidth: 1400) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            featured_islandology_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1400) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
