import React from "react";

import ArrowSVG from "../../svg/arrow.svg";
import View from "./View.js";
import viewerStyles from "./Viewer.module.css";

const Viewer = ( props ) => {
  const {
    items,
    isLive,
    onNextClick,
    onPrevClick,
    showNext,
    showPrev,
  } = props;

  return (
    <div className={viewerStyles.viewer}>
      {
        items.length > 0 && items.map( ( item, i ) => {
          return (
            <View
              key={i}
              item={item}
              viewerIsLive={isLive}
              animationDelay={(i * 50) + 25}
            />
          );
        } )
      }

      { showNext &&
        <div
          className={viewerStyles.next}
          onClick={onNextClick}
        >
          <img src={ArrowSVG} alt="" />
        </div>
      }
      { showPrev &&
        <div
          className={viewerStyles.prev}
          onClick={onPrevClick}
        >
          <img src={ArrowSVG} alt="" />
        </div>
      }
    </div>
  );
};

export default Viewer;
