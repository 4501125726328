import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import Number from "../Number/Number.js";
import componentsStyles from "../Components.module.css";
import viewerStyles from "./Viewer.module.css";

const View = ( props ) => {
  const {
    item,
    viewerIsLive,
    animationDelay,
  } = props;

  const itemStyles = [ viewerStyles.view ];
  if ( viewerIsLive ) itemStyles.push( viewerStyles.live );

  const imageStyles = [ componentsStyles.centeredMedia, componentsStyles.isLoaded ];
  
  const animationStyles = {
    opacity: 0,
    animationDelay: `${animationDelay}ms`,
  };

  return (
    <Link
      className={itemStyles.join( " " )}
      to={`/islandologies/${item.node.acf.islandology_number}/`}
    >
      { item.node.acf.islandology_bkgd && (
        <Img
          alt=""
          className={imageStyles.join( " " )}
          sizes={
            item.node.acf.islandology_bkgd &&
            item.node.acf.islandology_bkgd.localFile.childImageSharp.sizes
          }
          imgStyle={animationStyles}
        />
      ) }

      <div className={componentsStyles.gradient} />

      { viewerIsLive && (
        <Number value={item.node.acf.islandology_number} />
      ) }
    </Link>
  );
};

export default View;
