import React, { PureComponent } from "react";

import Header from "../../svg/thatsislandology.svg";
import ProgressRing from "../ProgressRing/ProgressRing.js";
import Slide from "./Slide.js";
import splashStyles from "./Splash.module.css";
import componentStyles from "../Components.module.css";

export default class Splash extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      itemInterval: null,
      progress: 0,
      progressInterval: null,
    };

    this.advanceItem = this.advanceItem.bind( this );
    this.reverseItem = this.reverseItem.bind( this );
    this.advanceProgress = this.advanceProgress.bind( this );
    this.hideSplash = this.hideSplash.bind( this );
    this.setIntervals = this.setIntervals.bind( this );
    this.clearIntervals = this.clearIntervals.bind( this );
  }

  componentDidMount() {
    this.setIntervals();
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals(){
    const { itemInterval, progressInterval } = this.state;

    clearInterval( itemInterval );
    clearInterval( progressInterval );
  }

  setIntervals(){
    const itemInterval = setInterval( this.advanceItem, 5000 );
    const progressInterval = setInterval( this.advanceProgress, 45 );

    this.setState( {
      itemInterval,
      progressInterval,
    } );
  }

  hideSplash( e ) {
    e.preventDefault();

    window.sessionStorage.setItem( "splashClosed", true );
    this.props.onExploreClick();
  }

  advanceItem() {
    const { items } = this.props;
    let nextIndex = 0;

    this.clearIntervals();
    this.setState( ( state ) => {
      if ( state.currentIndex < items.length - 1 ) {
        nextIndex = state.currentIndex + 1;
      }

      return {
        currentIndex: nextIndex,
        progress: 0,
      };
    } );
    this.setIntervals();
  }

  reverseItem() {
    const { items } = this.props;
    let nextIndex = items.length - 1;

    this.clearIntervals();
    this.setState( ( state ) => {
      if ( state.currentIndex > 0 ) {
        nextIndex = state.currentIndex - 1;
      }

      return {
        currentIndex: nextIndex,
        progress: 0,
      };
    } );
    this.setIntervals();
  }

  advanceProgress() {
    this.setState( ( state ) => {
      return state.progress < 100 && { progress: state.progress + 1 };
    } );
  }

  render() {
    const { currentIndex, progress } = this.state;
    const { isVisible, items } = this.props;

    const truncatedItems = items.length > 5 ? items.slice( 0, 5 ) : items;
    const splashClasses = [splashStyles.splash];
    if ( isVisible ) splashClasses.push( splashStyles.isVisible );

    return (
      <div className={splashClasses.join(" ")}>
        { truncatedItems.map( ( item, i ) => (
          <Slide
            key={i}
            item={item}
            id={i}
            index={currentIndex}
          />
        ) ) }

        <span className={splashStyles.header}>
          <img src={Header} alt="" />
          <p className={splashStyles.content}>
            Islandology is a state of mind and our way of life. It leads to unforgettable experiences you can only have here.
          </p>
          <a
            className={componentStyles.button}
            onClick={this.hideSplash}
            href="#0"
          >
            Explore<span className={splashStyles.explore}> them All</span>
          </a>
        </span>

        <div className={splashStyles.controls}>
          <div onClick={this.reverseItem}>
            <ProgressRing
              progress={0}
              radius={35}
              stroke={3}
            />
          </div>
          <div onClick={this.advanceItem}>
            <ProgressRing
              progress={progress}
              radius={35}
              right
              stroke={3}
            />
          </div>
        </div>
      </div>
    );
  }
}
