import React from "react";

import Header from "../../svg/thatsislandology.svg";
import capStyles from "./Cap.module.css";

const Cap = ( { view, isVisible } ) => {
  const styles = [ capStyles.container, capStyles[ view ] ];
  if ( isVisible ) styles.push( capStyles.isVisible );
  
  return (
    <div className={styles.join( " " )}>
      <div className={capStyles.content}>
        <img src={Header} alt="The Beaches of Fort Myers & SanibelThe Beaches of Fort Myers & Sanibel" />
        <p>Discover it only on <a href="//www.fortmyers-sanibel.com" rel="noopener noreferrer" target="_blank">The Beaches of Fort Myers & Sanibel</a></p>
      </div>
    </div>
  );
};

export default Cap;
