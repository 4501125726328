import React from "react";

import Seashell from "../../svg/shell.svg";
import loaderStyles from "./Loader.module.css";

const Loader = ( { isHidden } ) => {
  const loaderClasses = [loaderStyles.container];
  if ( isHidden ) loaderClasses.push( loaderStyles.isHidden );

  return (
    <div className={loaderClasses.join( " " )}>
      <img src={Seashell} className={loaderStyles.shell} alt="" />
    </div>
  );
};

export default Loader;
