import React from "react";
import numberStyles from "./Number.module.css";

const Number = ( {value, view} ) => {
  const numberClasses = [numberStyles.number];
  if ( view === "splash" ) numberClasses.push( numberStyles.isSplash );
  if ( view === "activeItem" ) numberClasses.push( numberStyles.isActiveItem );

  return (
    <span className={numberClasses.join(" ")}>
      { ( view === "splash" || view === "activeItem" ) &&
        <span className={numberStyles.no}>No.</span>
      }
      <span className={numberStyles.value}>
        {value}
      </span>
    </span>
  );
};

export default Number;
