import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import "../normalize.css";
import "../fonts/fonts.css";
import "./layout.css";
import islandologyStyles from "../components/Islandology/Islandology.module.css";
import Seashell from "../svg/shell.svg";

const Layout = ({ children, ...props }) => {
  const { view } = props;

  return (
    <main className={islandologyStyles[view]}>
      <Helmet
        meta={[
          {
            name: `apple-mobile-web-app-capable`,
            content: `yes`,
          },
          {
            name: `viewport`,
            content: `width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui`,
          },
        ].concat()}
      />

      <a
        className={islandologyStyles.logo}
        href="/"
      >
        <img src={Seashell} alt="" />
      </a>

      {children}
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
