import React from "react";
import Img from "gatsby-image";

import Number from "../Number/Number.js";
import slideStyles from "./Slide.module.css";
import componentsStyles from "../Components.module.css";

const Slide = ( props ) => {
  const { item, id, index } = props;

  const slideClasses = [slideStyles.slide];
  if ( id === index ) slideClasses.push(slideStyles.isActive);

  const imageStyles = [componentsStyles.centeredMedia, componentsStyles.isLoaded];
  const videoStyles = [componentsStyles.centeredMedia, componentsStyles.isLoaded];

  return (
    <div className={slideClasses.join( " " )}>
      { item.node.acf.islandology_bkgd && (
        <Img
          alt=""
          className={imageStyles.join( " " )}
          sizes={
            item.node.acf.islandology_bkgd &&
            item.node.acf.islandology_bkgd.localFile.childImageSharp.sizes
          }
        />
      ) }

      { item.node.acf.ambient_video && (
        <video
          className={videoStyles.join( " " )}
          autoPlay="autoplay"
          loop="loop"
          muted
          inline="true"
          playsInline
          src={item.node.acf.ambient_video.url}
        />
      ) }

      <div className={slideStyles.gradient} />

      <Number
        value={item.node.acf.islandology_number}
        view="splash"
      />
    </div>
  );
};

export default Slide;
