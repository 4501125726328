import React from "react";

import Cap from "../Cap/Cap.js";
import View from "../Viewer/View.js";
import viewerStyles from "./MobileViewer.module.css";

const MobileViewer = ( props ) => {
  const {
    items,
    isLive,
  } = props;

  return (
    <div className={viewerStyles.container}>
      {
        items.length > 0 && items.map( ( item, i ) => {
          return (
            <View
              key={i}
              item={item}
              viewerIsLive={isLive}
            />
          );
        } )
      }

      <Cap view="mobile" />
    </div>
  );
};

export default MobileViewer;
